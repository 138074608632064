.wrapper {
  display:  flex;
}

.wrapper__vert {
  flex-direction: column;
}

.AgameImageView {
  position: relative;
}

.AgameImageView svg {
  position:  absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

.AgameImageView img {
  display: block;
  width: 100%;
  height: auto;
}
